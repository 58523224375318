.lineLeft{
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 2%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;


  &::after{
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: 0%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    animation: drop 5s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  }
  
  }

  .lineRight{
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 2%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  
    &::after{
      content: '';
      display: block;
      position: absolute;
      height: 15vh;
      width: 100%;
      top: 0%;
      left: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
      animation: drop 5s 0s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    }
    
    }
  
    
@keyframes drop{
  0%{
    top: -5%;}
  100%{
    top: 100%;}
  }
