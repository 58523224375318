
.rotate360Class{
  animation-name: rotate360;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes rotate360{
    from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
}

